body {
  margin: 0;
  padding: 0;
  background-color: #F4F4F4;
}

body .ant-message .anticon.anticon-check-circle {
  display: none;
}

@keyframes modal-bounce { 
  0% { transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.3% { transform: matrix3d(0.76, 0, 0, 0, 0, 0.757, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  7.61% { transform: matrix3d(0.94, 0, 0, 0, 0, 0.906, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  8.61% { transform: matrix3d(0.982, 0, 0, 0, 0, 0.939, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  11.41% { transform: matrix3d(1.063, 0, 0, 0, 0, 1.005, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  12.91% { transform: matrix3d(1.085, 0, 0, 0, 0, 1.026, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  15.22% { transform: matrix3d(1.097, 0, 0, 0, 0, 1.043, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.22% { transform: matrix3d(1.09, 0, 0, 0, 0, 1.047, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  19.82% { transform: matrix3d(1.067, 0, 0, 0, 0, 1.042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { transform: matrix3d(1.022, 0, 0, 0, 0, 1.025, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  28.33% { transform: matrix3d(0.995, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  28.93% { transform: matrix3d(0.992, 0, 0, 0, 0, 1.009, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  33.43% { transform: matrix3d(0.984, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  39.44% { transform: matrix3d(0.991, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  51.55% { transform: matrix3d(1.003, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  61.66% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  69.77% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  83.98% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  87.99% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }  
}

@keyframes modal-shrink { 
  0% { opacity: 1; transform: scale(1); }
  30% { opacity: 0; }
  100% { transform: scale(0); opacity: 0 }
}

.ReactModal__Overlay {
  z-index: 5;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.ReactModal__Content {
  margin-right: 0px;
}

/*.ReactModal__Content.ReactModal__Content--after-open {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  justify-content: center !important;
  flex-direction: row !important;
  transform: none !important;
  margin-right: 0 !important;
}*/

.ReactModal__Content--after-open {
  animation: modal-bounce 1s linear both;
}

.ReactModal__Overlay--before-close .ReactModal__Content {
  animation: modal-shrink .8s ease;
}