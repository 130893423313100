@font-face {
  font-family: 'Muli';
  src: url(./fonts/Muli/Muli-VariableFont:wght.ttf) format('truetype-variations');
  font-weight: 100 600;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-100.eot');
  src: url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-100.eot?#iefix') format('embedded-opentype'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-100.woff2') format('woff2'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-100.woff') format('woff'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-100.ttf') format('truetype'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-100.svg#wf') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-300.eot');
  src: url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-300.eot?#iefix') format('embedded-opentype'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-300.woff2') format('woff2'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-300.woff') format('woff'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-300.ttf') format('truetype'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-300.svg#wf') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-500.eot');
  src: url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-500.eot?#iefix') format('embedded-opentype'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-500.woff2') format('woff2'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-500.woff') format('woff'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-500.ttf') format('truetype'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-500.svg#wf') format('svg');
  font-weight: 500;
  font-style: normal;
}

body {
  padding-top: 59px;
  background: #F4F4F4;
}

body.demo-warning {
  padding-top: 100px;
}

* {
  box-sizing: border-box;
}

::-moz-selection {
  background: #B2D7FF;
  color: inherit;
}
::selection {
  background: #B2D7FF;
  color: inherit;
}

a, a:active, a:focus {
  text-decoration: none;
}

.ct-series-a .ct-area {
  fill: rgb(246, 246, 246);
  /* fill: linear-gradient(to right, red, yellow, green); */
}

.ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut {
  stroke-width: 2px;
  stroke: rgb(106, 210, 203);
  /* stroke: linear-gradient(to right, red, yellow, green); */
  fill: transparent;
}

.ct-grid {
  stroke: rgba(0,0,0,.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-label.ct-vertical.ct-start {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
  height: 30px;
  width: 30px;
  font-weight: 100;
  font-size: 12px;
}

.ct-label.ct-horizontal.ct-end {
  height: 30px;
  width: 30px;
  font-weight: 100;
  font-size: 12px;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.loading-animation {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

h2 {
  font-weight: 300;
}

.ant-message {
  font-family: 'Museo Sans';
  z-index: 2000;
}

.ant-tooltip {
  z-index: 3002;
}

.ReactModal__Overlay {
  z-index: 1050;
}

kbd {
  background: #fff;
  color: #111423;
  padding: 6px;
  margin: 1px 2px;
  border-radius: 4px;
  box-shadow: 0 1px 5px #e6e6e6;
  font-family: 'Museo Sans';
}

.rw-popup-container {
  z-index: 1099 !important;
}

.video-photo-details .LinesEllipsis--clamped {
  white-space: nowrap;
  display: flex;
}
