body {
  margin: 0;
  padding: 0;
  background-color: #F4F4F4;
}

body .ant-message .anticon.anticon-check-circle {
  display: none;
}

@-webkit-keyframes modal-bounce { 
  0% { -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.3% { -webkit-transform: matrix3d(0.76, 0, 0, 0, 0, 0.757, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.76, 0, 0, 0, 0, 0.757, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  7.61% { -webkit-transform: matrix3d(0.94, 0, 0, 0, 0, 0.906, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.94, 0, 0, 0, 0, 0.906, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  8.61% { -webkit-transform: matrix3d(0.982, 0, 0, 0, 0, 0.939, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.982, 0, 0, 0, 0, 0.939, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  11.41% { -webkit-transform: matrix3d(1.063, 0, 0, 0, 0, 1.005, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.063, 0, 0, 0, 0, 1.005, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  12.91% { -webkit-transform: matrix3d(1.085, 0, 0, 0, 0, 1.026, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.085, 0, 0, 0, 0, 1.026, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  15.22% { -webkit-transform: matrix3d(1.097, 0, 0, 0, 0, 1.043, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.097, 0, 0, 0, 0, 1.043, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.22% { -webkit-transform: matrix3d(1.09, 0, 0, 0, 0, 1.047, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.09, 0, 0, 0, 0, 1.047, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  19.82% { -webkit-transform: matrix3d(1.067, 0, 0, 0, 0, 1.042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.067, 0, 0, 0, 0, 1.042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { -webkit-transform: matrix3d(1.022, 0, 0, 0, 0, 1.025, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.022, 0, 0, 0, 0, 1.025, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  28.33% { -webkit-transform: matrix3d(0.995, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.995, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  28.93% { -webkit-transform: matrix3d(0.992, 0, 0, 0, 0, 1.009, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.992, 0, 0, 0, 0, 1.009, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  33.43% { -webkit-transform: matrix3d(0.984, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.984, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  39.44% { -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.991, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  51.55% { -webkit-transform: matrix3d(1.003, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.003, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  61.66% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  69.77% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  83.98% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  87.99% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }  
}

@keyframes modal-bounce { 
  0% { -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.3% { -webkit-transform: matrix3d(0.76, 0, 0, 0, 0, 0.757, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.76, 0, 0, 0, 0, 0.757, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  7.61% { -webkit-transform: matrix3d(0.94, 0, 0, 0, 0, 0.906, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.94, 0, 0, 0, 0, 0.906, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  8.61% { -webkit-transform: matrix3d(0.982, 0, 0, 0, 0, 0.939, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.982, 0, 0, 0, 0, 0.939, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  11.41% { -webkit-transform: matrix3d(1.063, 0, 0, 0, 0, 1.005, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.063, 0, 0, 0, 0, 1.005, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  12.91% { -webkit-transform: matrix3d(1.085, 0, 0, 0, 0, 1.026, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.085, 0, 0, 0, 0, 1.026, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  15.22% { -webkit-transform: matrix3d(1.097, 0, 0, 0, 0, 1.043, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.097, 0, 0, 0, 0, 1.043, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.22% { -webkit-transform: matrix3d(1.09, 0, 0, 0, 0, 1.047, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.09, 0, 0, 0, 0, 1.047, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  19.82% { -webkit-transform: matrix3d(1.067, 0, 0, 0, 0, 1.042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.067, 0, 0, 0, 0, 1.042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { -webkit-transform: matrix3d(1.022, 0, 0, 0, 0, 1.025, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.022, 0, 0, 0, 0, 1.025, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  28.33% { -webkit-transform: matrix3d(0.995, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.995, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  28.93% { -webkit-transform: matrix3d(0.992, 0, 0, 0, 0, 1.009, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.992, 0, 0, 0, 0, 1.009, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  33.43% { -webkit-transform: matrix3d(0.984, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.984, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  39.44% { -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.991, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  51.55% { -webkit-transform: matrix3d(1.003, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.003, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  61.66% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  69.77% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  83.98% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  87.99% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }  
}

@-webkit-keyframes modal-shrink { 
  0% { opacity: 1; -webkit-transform: scale(1); transform: scale(1); }
  30% { opacity: 0; }
  100% { -webkit-transform: scale(0); transform: scale(0); opacity: 0 }
}

@keyframes modal-shrink { 
  0% { opacity: 1; -webkit-transform: scale(1); transform: scale(1); }
  30% { opacity: 0; }
  100% { -webkit-transform: scale(0); transform: scale(0); opacity: 0 }
}

.ReactModal__Overlay {
  z-index: 5;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.ReactModal__Content {
  margin-right: 0px;
}

/*.ReactModal__Content.ReactModal__Content--after-open {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  justify-content: center !important;
  flex-direction: row !important;
  transform: none !important;
  margin-right: 0 !important;
}*/

.ReactModal__Content--after-open {
  -webkit-animation: modal-bounce 1s linear both;
          animation: modal-bounce 1s linear both;
}

.ReactModal__Overlay--before-close .ReactModal__Content {
  -webkit-animation: modal-shrink .8s ease;
          animation: modal-shrink .8s ease;
}
.table-cell .rw-dropdown-list > .rw-widget-input, .table-cell .rw-multiselect > .rw-widget-input {
  min-height: 61px;
  padding-left: 20px;
}

.table-cell .rw-dropdown-list > .rw-widget-input > .rw-select, .table-cell .rw-multiselect > .rw-widget-input > .rw-select {
  visibility: hidden;
}

.table-cell .rw-dropdown-list.rw-open > .rw-widget-input > .rw-select, .table-cell .rw-multiselect.rw-open > .rw-widget-input > .rw-select {
  visibility: visible;
}

.table-cell:hover .rw-dropdown-list > .rw-widget-input > .rw-select, .table-cell:hover .rw-multiselect > .rw-widget-input > .rw-select {
  visibility: visible;
}

.invisible .rw-dropdown-list > .rw-widget-input, .invisible .rw-multiselect > .rw-widget-input {
  border: none;
}

.rw-btn.rw-btn-select {
  display: flex;
  justify-content: flex-end;
}

.transparent .rw-dropdown-list > .rw-widget-input, .transparent .rw-multiselect > .rw-widget-input {
  background-color: transparent;
}

.rw-dropdown-list.rw-state-focus > .rw-widget-container, .rw-multiselect.rw-state-focus > .rw-widget-container {
  box-shadow: none;
}

.rw-multiselect .rw-widget-input .rw-multiselect-taglist .rw-multiselect-tag {
  margin-right: 5px;
}

.rw-dropdown-list .rw-list-option.rw-state-focus, .rw-list-option.rw-state-focus:hover, .rw-multiselect .rw-list-option.rw-state-focus {
  border: none;
}

.rw-popup {
  border: none;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.1);
}

@-webkit-keyframes rotate-left {
  to {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  from {
    -webkit-transform: rotate(175deg);
            transform: rotate(175deg);
  }
}

@keyframes rotate-left {
  to {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  from {
    -webkit-transform: rotate(175deg);
            transform: rotate(175deg);
  }
}

@-webkit-keyframes rotate-right {
  from {
    -webkit-transform: rotate(-175deg);
            transform: rotate(-175deg);
  }
  to {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
}

@keyframes rotate-right {
  from {
    -webkit-transform: rotate(-175deg);
            transform: rotate(-175deg);
  }
  to {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
}

@-webkit-keyframes rotate-all {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate-all {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  -webkit-animation: rotate-all 1s linear infinite;
          animation: rotate-all 1s linear infinite;
  border-radius: 50px;
  box-shadow: inset 0px 0px 0px 2px #eee;
  position: absolute;
  left: calc(50% - 25px);
  top: 30px;
  z-index: 9;
}

.loading-spinner.centered {
  top: calc(50% - 25px);
}

.loading-spinner > .right, .loading-spinner > .left {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.loading-spinner .border {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 200px 0 0 200px;
  border: 2px solid #51c6e5;
}

.loading-spinner > .left {
    left: 0;
}

.loading-spinner > .left > .border {
  border-right: none;
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
  -webkit-transform-origin: right center;
          transform-origin: right center;
  -webkit-animation: rotate-left 0.75s linear infinite alternate;
          animation: rotate-left 0.75s linear infinite alternate;
}

.loading-spinner > .right {
  right: 0;
  display: none;
}
.loading-spinner > .right > .border {
  border-radius: 0 200px 200px 0;
  border-left: none;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-animation: rotate-right 0.75s linear infinite alternate;
          animation: rotate-right 0.75s linear infinite alternate;
}

.loading-spinner.-small {
  height: 30px;
  width: 30px;
}

@font-face {
  font-family: 'Muli';
  src: url(/static/media/Muli-VariableFont:wght.e8efee92.ttf) format('truetype-variations');
  font-weight: 100 600;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-100.eot');
  src: url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-100.eot?#iefix') format('embedded-opentype'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-100.woff2') format('woff2'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-100.woff') format('woff'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-100.ttf') format('truetype'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-100.svg#wf') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-300.eot');
  src: url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-300.eot?#iefix') format('embedded-opentype'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-300.woff2') format('woff2'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-300.woff') format('woff'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-300.ttf') format('truetype'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-300.svg#wf') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-500.eot');
  src: url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-500.eot?#iefix') format('embedded-opentype'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-500.woff2') format('woff2'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-500.woff') format('woff'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-500.ttf') format('truetype'),
       url('https://s3.eu-west-2.amazonaws.com/files-v2.peg.co/fonts/MuseoSans-500.svg#wf') format('svg');
  font-weight: 500;
  font-style: normal;
}

body {
  padding-top: 59px;
  background: #F4F4F4;
}

body.demo-warning {
  padding-top: 100px;
}

* {
  box-sizing: border-box;
}

::-moz-selection {
  background: #B2D7FF;
  color: inherit;
}
::selection {
  background: #B2D7FF;
  color: inherit;
}

a, a:active, a:focus {
  text-decoration: none;
}

.ct-series-a .ct-area {
  fill: rgb(246, 246, 246);
  /* fill: linear-gradient(to right, red, yellow, green); */
}

.ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut {
  stroke-width: 2px;
  stroke: rgb(106, 210, 203);
  /* stroke: linear-gradient(to right, red, yellow, green); */
  fill: transparent;
}

.ct-grid {
  stroke: rgba(0,0,0,.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-label.ct-vertical.ct-start {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
  height: 30px;
  width: 30px;
  font-weight: 100;
  font-size: 12px;
}

.ct-label.ct-horizontal.ct-end {
  height: 30px;
  width: 30px;
  font-weight: 100;
  font-size: 12px;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.loading-animation {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: -webkit-linear-gradient(left, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

@-webkit-keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

h2 {
  font-weight: 300;
}

.ant-message {
  font-family: 'Museo Sans';
  z-index: 2000;
}

.ant-tooltip {
  z-index: 3002;
}

.ReactModal__Overlay {
  z-index: 1050;
}

kbd {
  background: #fff;
  color: #111423;
  padding: 6px;
  margin: 1px 2px;
  border-radius: 4px;
  box-shadow: 0 1px 5px #e6e6e6;
  font-family: 'Museo Sans';
}

.rw-popup-container {
  z-index: 1099 !important;
}

.video-photo-details .LinesEllipsis--clamped {
  white-space: nowrap;
  display: flex;
}

