.table-cell .rw-dropdown-list > .rw-widget-input, .table-cell .rw-multiselect > .rw-widget-input {
  min-height: 61px;
  padding-left: 20px;
}

.table-cell .rw-dropdown-list > .rw-widget-input > .rw-select, .table-cell .rw-multiselect > .rw-widget-input > .rw-select {
  visibility: hidden;
}

.table-cell .rw-dropdown-list.rw-open > .rw-widget-input > .rw-select, .table-cell .rw-multiselect.rw-open > .rw-widget-input > .rw-select {
  visibility: visible;
}

.table-cell:hover .rw-dropdown-list > .rw-widget-input > .rw-select, .table-cell:hover .rw-multiselect > .rw-widget-input > .rw-select {
  visibility: visible;
}

.invisible .rw-dropdown-list > .rw-widget-input, .invisible .rw-multiselect > .rw-widget-input {
  border: none;
}

.rw-btn.rw-btn-select {
  display: flex;
  justify-content: flex-end;
}

.transparent .rw-dropdown-list > .rw-widget-input, .transparent .rw-multiselect > .rw-widget-input {
  background-color: transparent;
}

.rw-dropdown-list.rw-state-focus > .rw-widget-container, .rw-multiselect.rw-state-focus > .rw-widget-container {
  box-shadow: none;
}

.rw-multiselect .rw-widget-input .rw-multiselect-taglist .rw-multiselect-tag {
  margin-right: 5px;
}

.rw-dropdown-list .rw-list-option.rw-state-focus, .rw-list-option.rw-state-focus:hover, .rw-multiselect .rw-list-option.rw-state-focus {
  border: none;
}

.rw-popup {
  border: none;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.1);
}
