@keyframes rotate-left {
  to {
    transform: rotate(30deg);
  }
  from {
    transform: rotate(175deg);
  }
}

@keyframes rotate-right {
  from {
    transform: rotate(-175deg);
  }
  to {
    transform: rotate(-30deg);
  }
}

@keyframes rotate-all {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  animation: rotate-all 1s linear infinite;
  border-radius: 50px;
  box-shadow: inset 0px 0px 0px 2px #eee;
  position: absolute;
  left: calc(50% - 25px);
  top: 30px;
  z-index: 9;
}

.loading-spinner.centered {
  top: calc(50% - 25px);
}

.loading-spinner > .right, .loading-spinner > .left {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.loading-spinner .border {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 200px 0 0 200px;
  border: 2px solid #51c6e5;
}

.loading-spinner > .left {
    left: 0;
}

.loading-spinner > .left > .border {
  border-right: none;
  transform: rotate(10deg);
  transform-origin: right center;
  animation: rotate-left 0.75s linear infinite alternate;
}

.loading-spinner > .right {
  right: 0;
  display: none;
}
.loading-spinner > .right > .border {
  border-radius: 0 200px 200px 0;
  border-left: none;
  transform: rotate(-10deg);
  transform-origin: left center;
  animation: rotate-right 0.75s linear infinite alternate;
}

.loading-spinner.-small {
  height: 30px;
  width: 30px;
}
